.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
}
@font-face {
  font-family: Chimps;
  src: url(./Pixeboy-z8XGD.ttf);
}
.main{
  height: 100vh !important;
}
body{
  background-color: #ae96d4;
}
body {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.content{
  font-family: Chimps;
  color: #f6dd51;
  font-weight: bold;
  font-size:4vw;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  margin-top: 10px;
  height: 200px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}



.marquee2 {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 25vh;
  overflow: hidden;
  position: fixed;
            padding: 10px 10px 0px 10px;
            bottom: 0;
            width: 100%;

        
            top: 97%; right: 50%;
            transform: translate(50%,-30%);
/* 
            position: fixed;
            width: 100vw;
            max-width: 100%;
            height: 200px;
            overflow-x: hidden; */
    

}

.track2 {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee2 32s linear infinite;
  /* animation-direction: alternate; */
}
@keyframes marquee2 {
  from { transform: translateX(-50%); }
  to { transform: translateX(0); }
}
@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}
span{

}
.logo{
  position: absolute;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
  text-align: center;
  /* width: 40%;
  height: 40%; */
}
.btns
{
  /* position: absolute;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
  text-align: center; */
}
.links{
  display: flex;
  justify-content: center;
  align-items: center;
}
#container {
  position: relative;
}
#copyright {
  position: absolute;
  bottom: 0;
}
.twitter{
  background-color: #00acee;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  right: 5vw;
}
.chimp{
  width: auto;
  height: 40vh;
  margin-bottom: 5vh;
}
span{
  color: #a54ce7;
  }
button{

  border: none;
  font-family: Chimps;
  font-size: 4vw;
  /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
  cursor: pointer;
  padding-right: 25px;
}
.discord{
  background-color: #5C6BC0;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 5vw;
}
.btn__logo{

  width: 3vw;
  padding: 0.4rem;
  padding-right: 1rem;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(12%);

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:800px) {
.btn__logo{
  width: 40px;
  height: 40px;
}
  button{

  border: none;
  font-family: Chimps;
  font-size: 6.4vw;
 
  padding-right:10px;
}

.marquee2 {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;
  position: fixed;
            padding: 10px 10px 0px 10px;
            bottom: -8.5rem;
            width: 100%;

}
.marquee {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;
  position: fixed;
  padding: 10px 10px 0px 10px;
}

.content{
  font-size: 14vw;
}
.links{
  position: absolute;
  top: 110%; right: 50%;
  transform: translate(50%,-50%);
}
.logo{
  position: absolute;
  top: 40%; right: 50%;
  transform: translate(50%,-50%);
}
}

